import React, { useEffect, useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Button, Form, InputNumber, Popconfirm } from "antd";
import { DeleteOutlined, MenuOutlined } from "@ant-design/icons";
import getThumbnailUrl from "@utils/getThumbnailUrl";
export function SortableItem(props: any) {
  const [form] = Form.useForm();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: props.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const isVideo = props.data.mimeType?.includes("video");
  const thumbnailUrl = getThumbnailUrl(
    props.data.mimeType,
    props.data.processedUrls
  );

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="shadow w-full p-4 bg-white my-2 flex justify-between">
        <div className="flex gap-x-4 items-center">
          <MenuOutlined />
          <div
            style={{ height: "auto", width: 100 }}
            className="flex items-center"
          >
            <img src={thumbnailUrl} />
          </div>
          <div>
            <div>{props.data.name}</div>
            <div>{props.data.mimeType}</div>
          </div>
        </div>

        <div className="flex gap-x-2 items-center">
          <Form
            form={form}
            onValuesChange={(changedValues, allValues) =>
              props.onChange(allValues)
            }
            initialValues={{ duration: props.data.duration }}
          >
            <Form.Item name="duration">
              <InputNumber
                addonAfter="sec"
                disabled={isVideo}
                placeholder="Enter duration"
              />
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

function App(props: any) {
  const [items, setItems] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  useEffect(() => {
    if (props.items && props.items.length !== items.length) {
      setItems(props.items.map((item, index) => ({ id: item._id, ...item })));
    }
  }, [props.items]);
  useEffect(() => {
    props.onChange(items);
  }, [items]);
  const handleDelete = (item) => {
    console.log(item);
  };
  const handleChange = (index, values) => {
    setItems((items) => {
      items[index] = { ...items[index], ...values };
      return items;
    });
  };
  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            id={item.id}
            data={item}
            onDelete={() => handleDelete(item)}
            onChange={(values) => handleChange(index, values)}
          />
        ))}
      </SortableContext>
    </DndContext>
  );

  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((i) => i.id === active.id);
        const newIndex = items.findIndex((i) => i.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
}

export default App;
