import getThumbnailUrl from "@utils/getThumbnailUrl";
import { Button, Divider, Drawer, Input, Modal, Table } from "antd";
import dayjs, { Dayjs } from "dayjs";
import SelectedAssetsDND from "./SelectedAssetsDND";
import { PlusOutlined, CalendarFilled, SyncOutlined } from "@ant-design/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import service from "@services/asset";
import DatePicker from "./Core/DatePicker";
import useSearchParamsTableState from "@hooks/useSearchParamsTableState";
import { ProcessingStatusBadge } from "@views/Assets";
import AssetForm from "../views/Assets/Form";
const { RangePicker } = DatePicker;
function AssetSelector(props: any) {
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const dateFilterInput = useRef<any>(null);
  const [assetFormOpen, setAssetFormOpen] = useState(false);
  const [assetsOpen, setAssetsOpen] = useState(false);
  const [mimeTypes, setMimeTypes] = useState<string[]>([]);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [videoProcessingStatus, setVideoProcessingStatus] = useState<any>({});
  const [videoProcessingStatusLoading, setVideoProcessingStatusLoading] =
    useState(false);
  const [dateFilter, setDateFilter] =
    useState<[Dayjs | undefined, Dayjs | undefined]>();
  useEffect(() => {
    onRefresh();
    handleLoadDependencies();
  }, []);

  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [service.getUniqueMimeTypes()];
      const [mimeTypes] = await Promise.all(promises);
      setMimeTypes(mimeTypes);
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const getVideoProcessingStatus = useCallback(
    async (keys: string[]) => {
      try {
        setVideoProcessingStatusLoading(true);
        const statuses: any = await service.getVideoProcessingStatus(keys);
        setVideoProcessingStatus(statuses);
      } catch (error) {
      } finally {
        setVideoProcessingStatusLoading(false);
      }
    },
    [data]
  );
  useEffect(() => {
    if (data?.list) {
      const keys = data.list.map((d) => d.name);
      getVideoProcessingStatus(keys);
    }
  }, [data]);
  const handleEventFormSubmit = () => {
    setAssetFormOpen(false);
    onRefresh();
  };
  return (
    <div>
      <Drawer
        title="New Asset"
        placement="right"
        onClose={() => setAssetFormOpen(false)}
        size="large"
        open={assetFormOpen}
        maskClosable={false}
        destroyOnClose
      >
        <AssetForm hideTitle onSubmit={handleEventFormSubmit} />
      </Drawer>
      <Modal
        open={assetsOpen}
        onCancel={() => setAssetsOpen(false)}
        title="Add Assets"
        width={800}
      >
        <div className="flex justify-between gap-x-8 mb-4">
          <Input.Search
            placeholder="Search"
            onChange={(e) => setInput(e.target.value)}
          />
          <div className="flex gap-x-2">
            <Button
              icon={<SyncOutlined />}
              onClick={onRefresh}
              loading={loading}
            />
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setAssetFormOpen(true)}
            >
              New
            </Button>
          </div>
        </div>
        <Table
          loading={loading}
          pagination={{
            ...(tableState?.pagination || {}),
            total: data?.queryCount,
          }}
          bordered
          className="overflow-table"
          onChange={(pagination, filters, sorter) => {
            delete (sorter as any).column;
            setTableState({ pagination, filters, sorter });
          }}
          size="small"
          rowKey={(record) => record._id}
          rowSelection={{
            selectedRowKeys:
              !props.value || props.value.length === 0
                ? []
                : props.value.map((item) => item._id),
            onChange: (selectedRowKeys, selectedRows) => {
              props.onChange(selectedRows);
            },
          }}
          columns={[
            {
              title: "Preview",
              dataIndex: "processedUrls",
              render: (value, record) => {
                return (
                  <img
                    src={getThumbnailUrl(record.mimeType, value)}
                    style={{ height: 50, width: "auto" }}
                  />
                );
              },
            },
            { title: "Name", dataIndex: "displayName" },
            {
              title: "Type",
              dataIndex: "mimeType",
              filters: mimeTypes.map((type) => ({ text: type, value: type })),
            },
            {
              title: "Size",
              dataIndex: "dimension",
              render: (dimension: any) => (
                <div>
                  {dimension.width} x {dimension.height}{" "}
                  {dimension.duration
                    ? `(${Math.round(dimension.duration)}s)`
                    : ""}
                </div>
              ),
            },
            {
              title: "Status",
              key: "status",
              dataIndex: "status",
              render: (status, record) => {
                return (
                  <ProcessingStatusBadge
                    record={record}
                    status={
                      videoProcessingStatus[record.name]?.[0].status || status
                    }
                    isLoading={videoProcessingStatusLoading}
                  />
                );
              },
            },
            {
              title: "Upload Date",
              dataIndex: "createdAt",
              filterIcon: (filtered: boolean) => (
                <CalendarFilled
                  className={`${filtered ? "color-primary-500" : ""}`}
                />
              ),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => dateFilterInput.current?.focus(), 100);
                }
              },
              filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
              }) => (
                <div>
                  <div className="pt-2 px-2">
                    <RangePicker
                      ref={dateFilterInput}
                      value={selectedKeys.map((key) => dayjs(key)) as any}
                      onChange={(value) => {
                        setDateFilter(value);
                        setSelectedKeys(value.map((v) => v.toISOString()));
                      }}
                    />
                  </div>
                  <Divider className="my-2" />
                  <div className="pb-2 flex justify-between px-2">
                    <Button
                      size="small"
                      type="link"
                      disabled={!dateFilter || dateFilter[0] === undefined}
                      onClick={() => {
                        clearFilters();
                        setDateFilter([undefined, undefined]);
                      }}
                    >
                      Reset
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        confirm({ closeDropdown: true });
                      }}
                    >
                      OK
                    </Button>
                  </div>
                </div>
              ),
              render: (value) => dayjs(value).format("DD MMM, YYYY"),
            },
          ]}
          dataSource={data?.list}
        />
      </Modal>
      <div className="flex justify-between w-full items-center">
        <span className="font-semibold">Assets</span>
        <Button
          type="link"
          icon={<PlusOutlined />}
          onClick={() => setAssetsOpen(true)}
        >
          Add
        </Button>
      </div>
      <Divider className="my-2" />
      <SelectedAssetsDND items={props.value} onChange={props.onChange} />
    </div>
  );
}

export default AssetSelector;
