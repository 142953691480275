import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Divider,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import service from "@services/asset";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import UploadComponent from "@components/Upload";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Option } = Select;

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Device Form" },
  ]);
  const [form] = Form.useForm();
  const images = Form.useWatch("images", form);
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const setBreadcrumbs = useBreadcrumbs();
  const addFunctionRef = useRef<(defaultValue?: any) => void>(() => {});
  const [errors, setErrors] = useState<any>({
    basic: false,
    material: false,
    fluid: false,
    test: false,
  });
  const handleSubmit = (values: any) => {
    const images = values.images.map((image) => ({
      originalUrl: image.asset.url,
      name: image.name,
      displayName: image.displayName,
      mimeType: image.asset.type,
      dimension: image.asset.dimension,
    }));

    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .createMultiple(images)
        .then((response) => {
          if (!response.error) {
            if (props.onSubmit) {
              props.onSubmit();
            } else {
              navigate(BASE_PATH);
            }
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params) && params.entity) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  useEffect(() => {
    if (!images) {
      addFunctionRef.current();
    }
  }, [images]);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [];
      const [] = await Promise.all(promises);
      setMasters({});
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  const handleDefaultName = (index: number, images) => {
    console.log(index, images);

    const imageObject = images[index];
    if (!imageObject) {
      return;
    }
    console.log(imageObject);
    if (!imageObject.name) {
      let name = `${imageObject.url.split("/").pop()}`;
      name = `${name.substring(0, name.lastIndexOf("."))}`;
      console.log(name);

      imageObject.name = name;

      form.setFieldsValue({ images });
    }
  };
  const handleDefaultNames = (images) => {
    for (let idx in images) {
      if (!images[idx] || !images[idx].asset) {
        return;
      }

      if (!images[idx].name) {
        let name = `${images[idx].asset.url.split("/").pop()}`;
        name = `${name.substring(0, name.lastIndexOf("."))}`;

        images[idx].name = name;
      }
    }
    form.setFieldsValue({ images });
  };

  return (
    <div>
      {!props.hideTitle && (
        <Title level={3} className="mb-4">
          {id ? "Update" : "New"} {TITLE[0]}
        </Title>
      )}
      <Spin spinning={loadLoading}>
        <div className=" bg-white shadow px-4 py-4">
          <Form
            layout="vertical"
            form={form}
            onFinish={handleSubmit}
            onValuesChange={(changedValues, allValues) => {
              if (changedValues.images) {
                handleDefaultNames(allValues.images);
              }
            }}
          >
            <div>
              <Form.List name="images">
                {(fields, { add, remove }) => {
                  addFunctionRef.current = add;

                  return (
                    <>
                      <div className="flex justify-between items-center">
                        <div className="font-semibold">Assets List</div>
                        <Button
                          type="link"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          className="w-max"
                        >
                          Add Asset
                        </Button>
                      </div>
                      <Divider className="my-1" />
                      <div className="flex flex-col mt-3">
                        {fields.map((field, index) => (
                          <div key={field.key} className="flex gap-x-8">
                            <div>{index + 1}.</div>
                            <Form.Item
                              {...field}
                              name={[field.name, "displayName"]}
                              rules={[
                                { required: true, message: "Name is Required" },
                              ]}
                            >
                              <Input placeholder="Enter Name" />
                            </Form.Item>
                            <Form.Item
                              {...field}
                              name={[field.name, "asset"]}
                              valuePropName="fileList"
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "File is Required, please wait while it's uploading",
                                },
                              ]}
                            >
                              <UploadComponent />
                            </Form.Item>
                            <div>
                              <Button
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  );
                }}
              </Form.List>
            </div>

            <div className="mt-3">
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
