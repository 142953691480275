import { Routes, Route } from "react-router-dom";
import List from "./List";
import Form from "./Form";
import View from "./View";

export const MODEL = "campaign";
export const BASE_PATH = "/campaigns";
export const TITLE = ["Campaign", "Campaigns"];

export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/new`} element={<Form />} />
      <Route path={`/edit`} element={<Form />} />
      <Route path={`/`} element={<List />} />
      <Route path={`/:id`} element={<View />} />
    </Routes>
  );
}
