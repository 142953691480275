import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Row,
  Col,
  Modal,
  Switch,
  Popconfirm,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import assetService from "@services/asset";
import service from "@services/campaign";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { CalendarOutlined, DeleteOutlined } from "@ant-design/icons";
import DatePicker from "@components/Core/DatePicker";
import { Calendar, dayjsLocalizer, Views } from "react-big-calendar";
import dayjs from "dayjs";
import { nanoid } from "nanoid";
import deviceGroupService from "@services/deviceGroup";
import AssetSelector from "@components/AssetSelector";
const localizer = dayjsLocalizer(dayjs); // or globalizeLocalizer
const { Title } = Typography;
const { Option } = Select;

const REPEAT_OPTIONS = [
  { label: "Single", value: "single" },
  { label: "Daily", value: "daily" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
];

function ColorFormControl(props: any) {
  return (
    <div className="flex gap-x-2 ml-1">
      {[
        { background: "bg-red-500", color: "text-white" },
        { background: "bg-yellow-500", color: "text-black" },
        { background: "bg-green-500", color: "text-white" },
        { background: "bg-blue-500", color: "text-white" },
        { background: "bg-purple-500", color: "text-white" },
        { background: "bg-gray-500", color: "text-white" },
      ].map((color) => (
        <div
          className={`${color.background} rounded-full cursor-pointer ${
            props.value?.background === color.background &&
            `ring-2 ring-${color} ring-offset-2`
          } `}
          style={{ height: 20, width: 20 }}
          onClick={() => props.onChange(color)}
        ></div>
      ))}
    </div>
  );
}

function EventForm(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const allDay = Form.useWatch("allDay", form);
  const repeat = Form.useWatch("repeat", form);
  const [id, setId] = useState("");
  const [repeatId, setRepeatId] = useState("");
  const handleSubmit = (values: any) => {
    setLoading(true);
    props.onSubmit({
      ...values,
      start: values.start.toDate(),
      end: values.end.toDate(),
      repeatId: values.repeat !== "single" ? nanoid() : undefined,
    });
    setLoading(false);
  };
  const handleUpdate = (values: any) => {
    setLoading(true);
    props.onUpdate({
      ...values,
      id: id,
      start: values.start.toDate(),
      end: values.end.toDate(),
      repeatId: repeatId,
    });
    setLoading(false);
  };

  useEffect(() => {
    form.setFieldsValue({ ...props.defaultValues });
    if (props.defaultValues?.id) {
      setId(props.defaultValues.id);
    }
    if (props.defaultValues?.repeatId) {
      setRepeatId(props.defaultValues.repeatId);
    }
  }, [props]);
  return (
    <Form
      form={form}
      onFinish={id ? handleUpdate : handleSubmit}
      initialValues={{
        ...props.defaultValues,
        repeat: REPEAT_OPTIONS[0].value,
      }}
    >
      <Row gutter={8}>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="Title"
            name="title"
            rules={[{ required: true, message: "Title is required" }]}
          >
            <Input placeholder="Enter Title" />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="All Day"
            name="allDay"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="Start At"
            name="start"
            rules={[{ required: true, message: "Start At is required" }]}
          >
            <DatePicker
              className="w-full"
              placeholder="Select Start At"
              format="DD MMM, YY hh:mmA"
              disabled={allDay}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="End At"
            name="end"
            rules={[{ required: true, message: "End At is required" }]}
          >
            <DatePicker
              className="w-full"
              placeholder="Select End At"
              format="DD MMM, YY hh:mmA"
              disabled={allDay}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            className="w-full"
            label="Repeat"
            name="repeat"
            extra={
              repeat === "daily"
                ? "Applicable for the next 90 days"
                : repeat === "weekly"
                ? "Applicable for the next 52 weeks"
                : repeat === "monthly"
                ? "Applicable for the next 12 months"
                : undefined
            }
          >
            <Select
              placeholder="Select Repeat"
              defaultValue={REPEAT_OPTIONS[0].value}
            >
              {REPEAT_OPTIONS.map((option) => (
                <Option value={option.value}>{option.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            name="color"
            label="Color"
            rules={[{ required: true, message: "Color is required" }]}
          >
            <ColorFormControl />
          </Form.Item>
        </Col>
      </Row>
      <div className="mt-3">
        <Form.Item noStyle>
          <Space>
            <Button type="default" onClick={props.onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading}>
              Submit
            </Button>
          </Space>
        </Form.Item>
      </div>
    </Form>
  );
}

function EventWrapper(props: any) {
  return (
    <div
      className={`${props.event.color.background}  ${props.event.color.color}`}
    >
      {props.children}
    </div>
  );
}

function EventCard(props: any) {
  return (
    <div className="flex flex-col justify-between">
      <div>
        <div className="flex items-center gap-x-2">
          <div
            className={`${props.data.color.background} rounded-full cursor-pointer `}
            style={{ height: 10, width: 10 }}
          ></div>
          <div className="font-semibold text-lg">{props.data.title}</div>
        </div>
        <div className="mt-4 ml-5">
          <div className="flex items-center gap-x-2">
            <CalendarOutlined />
            {props.data.repeat === "single" ? (
              <div>
                {dayjs(props.data.start).format("DD MMM, YY hh:mmA")} -{" "}
                {dayjs(props.data.end).format(" hh:mmA")}
              </div>
            ) : (
              <div>
                {dayjs(props.data.start).format("hh:mmA")} -{" "}
                {dayjs(props.data.end).format("hh:mmA")} (Repeats{" "}
                {props.data.repeat})
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-x-4 justify-end mt-8">
        <Popconfirm
          title="Are you sure to delete this task?"
          onConfirm={() => props.onDelete(props.data)}
          okText="Yes"
          cancelText="No"
        >
          <a href="#" className="flex items-center gap-x-2">
            <DeleteOutlined /> Delete{" "}
            {props.data.repeat !== "single" && "Series"}
          </a>
        </Popconfirm>
      </div>
    </div>
  );
}

function Scheduler(props: any) {
  const [events, setEvents] = useState([]);
  const [formVisible, setFormVisible] = useState(false);
  const [eventVisible, setEventVisible] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<any>();
  const isEventOverlapping = (
    newEvent: any,
    existingEvents: any[]
  ): boolean => {
    return existingEvents.some((event) => {
      return (
        (newEvent.start < event.end && newEvent.start >= event.start) ||
        (newEvent.end > event.start && newEvent.end <= event.end) ||
        (newEvent.start <= event.start && newEvent.end >= event.end)
      );
    });
  };
  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const newEvent = {
        start: dayjs(start).toDate(),
        end: dayjs(end).toDate(),
      };
      if (!isEventOverlapping(newEvent, events)) {
        setCurrentEvent({ start: dayjs(start), end: dayjs(end) });
        setFormVisible(true);
      } else {
        alert("Cannot create an event that overlaps with an existing event.");
      }
    },
    [events]
  );
  useEffect(() => {
    props.onChange(events);
    setFormVisible(false);
    setEventVisible(false);
  }, [events]);
  useEffect(() => {
    if (events.length === 0 && props.defaultValues?.length > 0) {
      console.log(props.defaultValues);
      setEvents(
        props.defaultValues.map((e) => ({
          ...e,
          start: dayjs(e.start).toDate(),
          end: dayjs(e.end).toDate(),
        }))
      );
    }
  }, [props.defaultValues, events]);
  const handleSelectEvent = useCallback((event) => {
    setCurrentEvent({
      ...event,
      start: dayjs(event.start),
      end: dayjs(event.end),
    });
    setEventVisible(true);
  }, []);

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  );
  const components = useMemo(
    () => ({
      event: (props) => {
        return (
          <div>
            <div>{props.event.title}</div>
          </div>
        );
      },
      eventWrapper: memo(EventWrapper),
    }),
    []
  );
  const handleDelete = (event) => {
    if (event.repeatId) {
      setEvents(events.filter((e) => e.repeatId !== event.repeatId));
    }
    if (!event.repeatId) {
      setEvents(events.filter((e) => e.id !== event.id));
    }
  };
  return (
    <div className="height600">
      <Calendar
        components={components}
        defaultDate={defaultDate}
        defaultView={Views.MONTH}
        events={events}
        localizer={localizer}
        onSelectEvent={handleSelectEvent}
        onSelectSlot={handleSelectSlot}
        selectable
        scrollToTime={scrollToTime}
        style={{ height: 600 }}
      />
      <Modal
        open={formVisible}
        onCancel={() => setFormVisible(false)}
        footer={null}
        title="Edit Event"
        destroyOnClose
      >
        <EventForm
          onUpdate={(values) => {}}
          onSubmit={(values) => {
            if (values.repeat === "daily") {
              const DAYS = 90;
              const dailyEvents = [];
              for (let i = 0; i < DAYS; i++) {
                dailyEvents.push({
                  ...values,
                  id: nanoid(),
                  start: dayjs(values.start).add(i, "days").toDate(),
                  end: dayjs(values.end).add(i, "days").toDate(),
                });
              }
              return setEvents([...events, ...dailyEvents]);
            }
            if (values.repeat === "weekly") {
              const WEEKS = 52;
              const weeklyEvents = [];
              for (let i = 0; i < WEEKS; i++) {
                weeklyEvents.push({
                  ...values,
                  id: nanoid(),
                  start: dayjs(values.start).add(i, "weeks").toDate(),
                  end: dayjs(values.end).add(i, "weeks").toDate(),
                });
              }
              return setEvents([...events, ...weeklyEvents]);
            }
            if (values.repeat === "monthly") {
              const MONTHS = 12;
              const monthlyEvents = [];
              for (let i = 0; i < MONTHS; i++) {
                monthlyEvents.push({
                  ...values,
                  id: nanoid(),
                  start: dayjs(values.start).add(i, "months").toDate(),
                  end: dayjs(values.end).add(i, "months").toDate(),
                });
              }
              return setEvents([...events, ...monthlyEvents]);
            }
            setEvents([...events, { ...values, id: nanoid() }]);
          }}
          defaultValues={currentEvent || null}
        />
      </Modal>
      <Modal
        open={eventVisible}
        onCancel={() => setEventVisible(false)}
        footer={null}
        title="Event Details"
        destroyOnClose
      >
        <EventCard data={currentEvent} onDelete={handleDelete} />
      </Modal>
    </div>
  );
}

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Device Form" },
  ]);
  const [form] = Form.useForm();
  const deviceGroups = Form.useWatch("deviceGroups", form);
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const [groups, setGroups] = useState<any>([]);
  const [defaultSlots, setDefaultSlots] = useState<any>([]);
  const setBreadcrumbs = useBreadcrumbs();

  const handleSubmit = (values: any) => {
    console.log(values);
    values.assets = values.assets.map((asset: any) => ({
      asset: asset._id,
      duration: asset.duration,
    }));
    console.log(values);
    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create(values)
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };

  const slotsValidator = (rule: any, value: any) => {
    if (value.length === 0) {
      return Promise.reject("Slots are required");
    }
    if (value?.length > 0) {
      let overlappingSlots: any[] = [];
      const isOverlapping = (slots: any[]) => {
        for (let i = 0; i < slots.length; i++) {
          for (let j = i + 1; j < slots.length; j++) {
            if (
              (slots[i].start < slots[j].end &&
                slots[i].start >= slots[j].start) ||
              (slots[i].end > slots[j].start && slots[i].end <= slots[j].end) ||
              (slots[i].start <= slots[j].start && slots[i].end >= slots[j].end)
            ) {
              overlappingSlots = [...overlappingSlots, [slots[i], slots[j]]];
              return true;
            }
          }
        }
        return false;
      };
      if (isOverlapping(value)) {
        return Promise.reject(
          <div>
            The following events are overlapping:
            <div>
              {overlappingSlots.map((slots: any) => {
                return (
                  <div className="mb-4">
                    <div className="flex gap-x-2">
                      {slots[0].title}: {slots[0].start.toLocaleString()} -{" "}
                      {slots[0].end.toLocaleString()}
                    </div>
                    <div className="flex gap-x-2">
                      {slots[1].title}: {slots[1].start.toLocaleString()} -{" "}
                      {slots[1].end.toLocaleString()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      }

      return Promise.resolve();
    }
  };

  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
          setDefaultSlots(payload.slots);
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleLoadCampaignsForGroups = async () => {
    try {
      setDependentsLoading(true);
      const response = await service.getCampaignsForGroups(deviceGroups);
      console.log(response);
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };
  useEffect(() => {
    handleLoadCampaignsForGroups();
  }, [deviceGroups]);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [deviceGroupService.getAll()];
      const [deviceGroups] = await Promise.all(promises);
      setMasters({});
      setGroups(deviceGroups);
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };

  return (
    <div>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <Spin spinning={loadLoading}>
        <div className="mt-4">
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <div className="bg-white p-4 shadow">
              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    className="w-full"
                    label="Code"
                    name="code"
                    rules={[
                      { required: true, message: "Code is required" },
                      {
                        validator: async (_, value) => {
                          const response = await service.checkCode(
                            value?.trim().toUpperCase(),
                            id
                          );
                          return response.exists
                            ? Promise.reject("Code already exists")
                            : Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Input placeholder="Enter Campaign Code" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="w-full"
                    label="Name"
                    name="name"
                    rules={[{ required: true, message: "Name is required" }]}
                  >
                    <Input placeholder="Enter Campaign Name" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="w-full"
                    label="Description"
                    name="description"
                    rules={[
                      { required: true, message: "Description is required" },
                    ]}
                  >
                    <Input.TextArea placeholder="Enter Description" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    className="w-full"
                    label="Device Group"
                    name="deviceGroups"
                    rules={[
                      { required: true, message: "Device Group is required" },
                    ]}
                  >
                    <Select placeholder="Select Device Group" mode="multiple">
                      {groups.map((group) => (
                        <Option value={group._id}>{group.name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="bg-white p-4 shadow mt-3">
              <Form.Item
                name="slots"
                rules={[
                  {
                    validator: slotsValidator,
                  },
                ]}
              >
                <Scheduler defaultValues={defaultSlots} />
              </Form.Item>
            </div>
            <div className="bg-white p-4 shadow mt-3">
              <Form.Item name="assets">
                <AssetSelector />
              </Form.Item>
            </div>
            <div className="mt-3">
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
