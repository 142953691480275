import AssetSelector from "@components/AssetSelector";
import { Button, Form, Space, Spin, Typography, message } from "antd";
import service from "@services/campaign";
import { useEffect, useRef, useState } from "react";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import useClient from "@hooks/useClient";
const { Title } = Typography;
function DefaultCampaign(props: any) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const setBreadcrumbs = useBreadcrumbs();
  const breadcrumbsRef = useRef([
    { label: "Settings" },
    { label: "Default Campaign" },
  ]);
  const client = useClient();

  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, [client]);
  useEffect(() => {
    setViewLoading(true);
    service
      .getDefault()
      .then((response) => {
        console.log(response);
        form.setFieldsValue(response);
      })
      .finally(() => {
        setViewLoading(false);
      });
  }, []);
  const handleSubmit = async (values: any) => {
    try {
      setLoading(true);
      values.assets = values.assets.map((asset: any) => ({
        asset: asset._id,
        duration: asset.duration,
      }));
      await service.createDefault({ ...values, name: "default", code: "DEF" });
      message.success("Default campaign updated successfully");
    } catch (error) {
      message.error("Failed to update default campaign");
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Title level={3}>Default Campaign</Title>
      <Spin spinning={viewLoading}>
        <Form form={form} onFinish={handleSubmit}>
          <div className="bg-white p-4 shadow mt-3">
            <Form.Item name="assets">
              <AssetSelector />
            </Form.Item>
          </div>
          <div className="mt-2 bg-white shadow px-4 py-4">
            <div className="">
              <Form.Item noStyle>
                <Space>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Save
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Spin>
    </div>
  );
}

export default DefaultCampaign;
