import { Store } from "@store/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useIsAdmin() {
  const authState = useSelector((state: Store) => state.auth);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  useEffect(() => {
    if (authState.user?.isAdmin) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [authState.user]);

  return isAdmin;
}

export default useIsAdmin;
