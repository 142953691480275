import { Button, Descriptions, Divider, Spin, Table, Typography } from "antd";
import { useParams } from "react-router-dom";
import { TITLE, BASE_PATH } from ".";
import useBreadcrumbs from "@hooks/useBreadcrumbs";
import { useEffect, useRef, useState } from "react";
import service from "@services/client";
import { useNavigate } from "react-router-dom";
import deviceService from "@services/device";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
const { Title } = Typography;

function DevicesTable(props: any) {
  const { data, loading } = props;
  const columns: any[] = [
    { key: "macAddress", title: "MAC Address", dataIndex: "macAddress" },
    { key: "remote", title: "Remote", dataIndex: "remote" },
    { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
    { key: "platformName", title: "Platform Name", dataIndex: "platformName" },
    { key: "vendorName", title: "Vendor Name", dataIndex: "vendorName" },
    { key: "vendorId", title: "Vendor Id", dataIndex: "vendorId" },
    {
      key: "vendorProductId",
      title: "Vendor Product Id",
      dataIndex: "vendorProductId",
    },
    { key: "productId", title: "Product Id", dataIndex: "productId" },
    {
      key: "launcherVersion",
      title: "Launcher Version",
      dataIndex: "launcherVersion",
    },
    { key: "contentType", title: "Content Type", dataIndex: "contentType" },
    { key: "stage", title: "Stage", dataIndex: "stage" },
    { key: "flag", title: "Flag", dataIndex: "flag" },
    {
      key: "recordVersion",
      title: "Record Version",
      dataIndex: "recordVersion",
    },
  ];
  return (
    <div className="processed-data-table">
      <Table
        dataSource={data}
        columns={columns}
        pagination={false}
        loading={loading}
      />
    </div>
  );
}

function View(props: any) {
  const navigate = useNavigate();
  const { id } = useParams();
  const setBreadcrumbs = useBreadcrumbs();
  const [data, setData] = useState<any>({});
  const [devices, setDevices] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [devicesLoading, setDevicesLoading] = useState(false);
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "View Client" },
  ]);
  useEffect(() => {
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  useEffect(() => {
    if (id) {
      setLoading(true);
      setDevicesLoading(true);
      service
        .get(id)
        .then((res) => {
          setData(res);
          breadcrumbsRef.current = [
            { label: TITLE[1], url: BASE_PATH },
            { label: res.name },
          ];
          setBreadcrumbs(breadcrumbsRef.current);
        })
        .finally(() => setLoading(false));
      service
        .getDevicesForClient(id)
        .then((res) => {
          setDevices(res);
        })
        .finally(() => {
          setDevicesLoading(false);
        });
    }
  }, [id]);

  return (
    <Spin spinning={loading}>
      <div className="flex justify-between">
        <Title level={3}>
          {data?.name} ({data?.code})
        </Title>
        <Button icon={<EditOutlined />} onClick={() => handleForm(id)}>
          Edit
        </Button>
      </div>
      {data?.address && (
        <div className="bg-white p-3 shadow">
          <div>
            <span className="font-semibold">Address Information</span>
          </div>
          <Divider className="my-2" />
          <div>
            <Descriptions
              size="small"
              bordered
              column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }}
            >
              <Descriptions.Item label="Address Line 1">
                {data?.address.address1} {data?.address.address2}
              </Descriptions.Item>
              <Descriptions.Item label="City">
                {data?.address.city}
              </Descriptions.Item>
              <Descriptions.Item label="State">
                {data?.address.state}
              </Descriptions.Item>
              <Descriptions.Item label="Pincode">
                {data?.address.pincode}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
      {data?.contact && (
        <div className="bg-white p-3 shadow mt-3">
          <div>
            <span className="font-semibold">Contact Information</span>
          </div>
          <Divider className="my-2" />
          <div>
            <Descriptions size="small" bordered>
              <Descriptions.Item label="Name">
                {data?.contact.name}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {data?.contact.email}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">
                {data?.contact.phone}
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      )}
      <div className="bg-white p-3 shadow mt-3">
        <div>
          <span className="font-semibold">Registered Devices</span>
        </div>
        <Divider className="my-2" />
        <DevicesTable data={devices} loading={devicesLoading} />
      </div>
    </Spin>
  );
}
export default View;
