import { useEffect, useState, useRef, useCallback } from "react";
import {
  Row,
  Col,
  Typography,
  Button,
  Input,
  Select,
  Table,
  Space,
  Popover,
  List,
  Checkbox,
  Form,
  Modal,
  Divider,
  Tag,
} from "antd";
import {
  PlusOutlined,
  SearchOutlined,
  EditOutlined,
  LockOutlined,
  ArrowRightOutlined,
  ExportOutlined,
  LoadingOutlined,
  SyncOutlined,
} from "@ant-design/icons";

import { debounce } from "throttle-debounce";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { TITLE, BASE_PATH } from ".";
import service from "@services/campaign";

import useSearchParamsTableState from "hooks/useSearchParamsTableState";
import useBreadcrumbs from "hooks/useBreadcrumbs";
import { TbColumns3 } from "react-icons/tb";
import useSelectedColumnsState from "@hooks/useSelectedColumnsState";
import saveAs from "file-saver";

const { Text, Title } = Typography;
const { Search } = Input;
const { Option } = Select;

const MASTER_KEYS = [];
const ADDITIONAL_COLUMNS = [
  {
    title: "Active",
    dataIndex: "isActive",
    key: "isActive",
    render: (text) => (text ? "Yes" : "No"),
  },
  { key: "remote", title: "Remote", dataIndex: "remote" },
  { key: "osVersion", title: "OS Version", dataIndex: "osVersion" },
  { key: "platformName", title: "Platform Name", dataIndex: "platformName" },

  {
    key: "launcherVersion",
    title: "Launcher Version",
    dataIndex: "launcherVersion",
  },
  { key: "contentType", title: "Content Type", dataIndex: "contentType" },
  { key: "stage", title: "Stage", dataIndex: "stage" },
  { key: "flag", title: "Flag", dataIndex: "flag" },
  {
    key: "recordVersion",
    title: "Record Version",
    dataIndex: "recordVersion",
  },
  {
    title: "Updated",
    dataIndex: "updatedAt",
    key: "updatedAt",

    render: (text, record, index) => dayjs(text).format("DD/MM/YYYY"),
  },
];

export default function Entities(props: any) {
  const breadcrumbsRef = useRef([{ label: TITLE[1], url: BASE_PATH }]);
  const [input, setInput, tableState, setTableState, data, loading, onRefresh] =
    useSearchParamsTableState({ onFetch: service.paginate });
  const navigate = useNavigate();
  const setBreadcrumbs = useBreadcrumbs();
  const [resetPasswordVisible, setResetPasswordVisible] = useState("");
  const [toggleLoading, setToggleLoading] = useState(false);
  const [toggleKey, setToggleKey] = useState("");
  const [exportLoading, setExportLoading] = useState(false);
  const [videoProcessingStatus, setVideoProcessingStatus] = useState<any>({});
  const [videoProcessingStatusLoading, setVideoProcessingStatusLoading] =
    useState(false);
  const { selectedColumns, setSelectedColumns } = useSelectedColumnsState({
    breadcrumbs: breadcrumbsRef.current,
    availableColumns: ADDITIONAL_COLUMNS,
    defaultSelected: [],
  });

  useEffect(() => {
    loadMastersDebounced();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const [masters, setMasters] = useState<any>({});

  const getColumnsFilters = useCallback(
    (key: string) => {
      return masters[key]
        ? masters[key].map((entity) => ({
            text: entity.name,
            value: entity._id,
          }))
        : [];
    },
    [masters]
  );
  const loadMasters = useCallback(() => {
    const promises: any[] = [];
    for (let masterKey of MASTER_KEYS) {
      promises.push(
        masterKey.service
          .getAll()
          .then((p) => ({ key: masterKey.value, data: p }))
      );
    }
    Promise.all(promises).then((payload) => {
      const output: any = {};
      payload.forEach((m) => (output[m.key] = m.data));
      setMasters(output);
    });
  }, []);
  const loadMastersDebounced = useCallback(debounce(300, loadMasters), []);

  const columns: any[] = [
    { title: "Name", key: "name", dataIndex: "name" },
    {
      title: "Actions",
      key: "actions",
      align: "right",
      render: (text, record, index) => (
        <Space>
          <Button
            icon={<EditOutlined />}
            onClick={() => handleForm(record._id)}
            type="link"
            size="small"
          />
          <Button
            icon={<ArrowRightOutlined />}
            onClick={() => handleView(record._id)}
            type="link"
            size="small"
          />
        </Space>
      ),
    },
    ...(selectedColumns as any[]),
  ];

  const handleForm = (entityId?: string) => {
    if (entityId) {
      navigate(`${BASE_PATH}/edit?entity=${entityId}`);
    } else {
      navigate(`${BASE_PATH}/new`);
    }
  };
  const handleView = (entityId?: string) => {
    navigate(`${BASE_PATH}/${entityId}`);
  };
  const handleBulk = () => {
    navigate(`${BASE_PATH}/bulk`);
  };

  return (
    <div>
      <Row justify="space-between">
        <Col>
          <Title level={3}>All {TITLE[1]}</Title>
        </Col>
        <Col></Col>
        <Col>
          <Space>
            <Popover
              overlayClassName="popover-content-w-full"
              content={
                <List
                  size="small"
                  dataSource={ADDITIONAL_COLUMNS}
                  renderItem={(item) => (
                    <List.Item className="flex items-center gap-x-2 justify-start">
                      <div className="w-max">
                        <Checkbox
                          checked={selectedColumns.some(
                            (c) => c.key === item.key
                          )}
                          onChange={(e) => {
                            setSelectedColumns(item);
                          }}
                          children={item.title}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              }
              title="Select Columns"
              trigger="click"
            >
              <Button icon={<TbColumns3 />} />
            </Popover>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => handleForm()}
            >
              New {TITLE[0]}
            </Button>
          </Space>
        </Col>
      </Row>
      <div className="mt-4 bg-white shadow">
        <div className="flex justify-between p-4">
          <Search
            placeholder={`Search ${TITLE[1]}`}
            enterButton={<SearchOutlined />}
            className="w-max"
            size="middle"
            onChange={(event) => {
              setInput(event.target.value);
            }}
            value={input}
          />
          {data?.queryCount !== data?.totalCount && (
            <Text className="flex items-center">
              Showing {data.queryCount} of {data?.totalCount} entries
              <Button
                type="link"
                onClick={() => {
                  setInput("");
                }}
              >
                Clear Search
              </Button>
            </Text>
          )}
          <Button icon={<SyncOutlined />} onClick={onRefresh} />
        </div>

        <Row className="mt-2">
          <Col span={24}>
            <Table
              loading={loading}
              dataSource={data?.list}
              columns={columns}
              pagination={{
                ...(tableState?.pagination || {}),
                total: data?.queryCount,
              }}
              bordered
              className="overflow-table"
              onChange={(pagination, filters, sorter) => {
                delete (sorter as any).column;
                setTableState({ pagination, filters, sorter });
              }}
              rowKey={(record) => record._id}
              size="small"
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}
