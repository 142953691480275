import Login from "@views/Login";
import { Layout } from "antd";
import { Route, Routes } from "react-router-dom";
const { Content } = Layout;
const TestComponent = (props) => {
  return <div>Hi</div>;
};
export default function AuthLayout(props: any) {
  return (
    <Layout>
      <Content>
        <Routes>
          <Route path="/" element={<Login />} />
        </Routes>
      </Content>
    </Layout>
  );
}
