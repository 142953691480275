import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const TYPE = "project";

export interface ProjectState {
  currentSelected: any;
}
const INITIAL_STATE: ProjectState = {
  currentSelected: null,
};

export const setCurrentSelectedAsync = createAsyncThunk(
  `${TYPE}/SET_CURRENT_SELECTED`,
  async (payload: any, thunkAPI) => {
    try {
      localStorage.setItem("project:currentSelected", JSON.stringify(payload));
      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const slice = createSlice({
  name: TYPE,
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentSelected: (state, action) => {
      state.currentSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentSelectedAsync.fulfilled, (state, action) => {
      state.currentSelected = action.payload;
    });
  },
});
export const { setCurrentSelected } = slice.actions;
export default slice.reducer;
