import { Menu, Layout, Drawer, Tag } from "antd";
import SubMenu from "antd/lib/menu/SubMenu";
import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  DatabaseFilled,
  StarOutlined,
  DatabaseOutlined,
  DashboardOutlined,
  UserSwitchOutlined,
  BlockOutlined,
  TeamOutlined,
  PictureOutlined,
  AppstoreOutlined,
  DesktopOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  FaHeadset,
  FaEnvelope,
  FaFileAlt,
  FaUsers,
  FaTachometerAlt,
  FaCog,
  FaDatabase,
} from "react-icons/fa";
import { IoIosPeople, IoMdPeople } from "react-icons/io";
import {
  MdOutlineHistory,
  MdOutlineInventory2,
  MdOutlineStore,
} from "react-icons/md";
import { BsTable, BsListCheck, BsFileEarmarkText } from "react-icons/bs";
import { FiUsers, FiShoppingCart, FiSettings } from "react-icons/fi";
import {
  HiOutlineCurrencyRupee,
  HiOutlineDocumentReport,
} from "react-icons/hi";
import { AiOutlineDashboard } from "react-icons/ai";
import MenuItem from "antd/lib/menu/MenuItem";
import { useSelector } from "react-redux";
import { Store } from "@store/index";
import useIsAdmin from "@hooks/useIsAdmin";

const { Sider } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  onClick: any,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    onClick,
  } as any;
}
const ADMIN_NAV_ITEMS: any = [
  {
    id: "devices",
    title: "Devices",
    icon: <BlockOutlined />,
    link: "devices",
  },
  {
    id: "clients",
    title: "Clients",
    icon: <TeamOutlined />,
    link: "clients",
  },
];
const CLIENT_NAV_ITEMS: any = [
  {
    id: "campaigns",
    title: "Campaigns",
    icon: <DesktopOutlined />,
    link: "campaigns",
  },
  {
    id: "deviceGroups",
    title: "Device Groups",
    icon: <AppstoreOutlined />,
    link: "device-groups",
  },
  {
    id: "registeredDevices",
    title: "Registered Devices",
    icon: <BlockOutlined />,
    link: "registered-devices",
  },
  {
    id: "assets",
    title: "Assets",
    icon: <PictureOutlined />,
    link: "assets",
  },
  {
    id: "settings",
    title: "Settings",
    icon: <SettingOutlined />,
    children: [
      {
        id: "defaultCampaign",
        title: "Default Campaign",
        link: "settings/default-campaign",
      },
    ],
  },
];
export default function Navigation(props: {
  showNav: boolean;
  collapsed: boolean;
  onCollapse: any;
  onShowDrawer: any;
  showDrawer: boolean;
  onNavigate: any;
  authState: any;
}) {
  const uiState = useSelector((state: Store) => state.ui);
  const isAdmin = useIsAdmin();
  const [navItems, setNavItems] = useState(CLIENT_NAV_ITEMS);
  useEffect(() => {
    if (isAdmin) {
      setNavItems(ADMIN_NAV_ITEMS);
    } else {
      setNavItems(CLIENT_NAV_ITEMS);
    }
  }, [isAdmin]);
  const NavMenu = (
    <div>
      <Menu
        theme="light"
        mode="inline"
        items={navItems
          .filter((navItem) =>
            navItem.isAdminOnly ? props.authState.user?.isAdmin : true
          )
          .map((item) =>
            getItem(
              item.title,
              item.id,
              item.link ? () => props.onNavigate(item.link) : undefined,
              item.icon,
              item.children?.map?.((c) =>
                getItem(c.title, c.link, () => props.onNavigate(c.link))
              )
            )
          )}
      ></Menu>
    </div>
  );
  return props.showNav && uiState.showSider ? (
    <Sider
      collapsible
      collapsed={props.collapsed}
      onCollapse={props.onCollapse}
      theme="light"
      className="shadow"
    >
      {NavMenu}
    </Sider>
  ) : (
    <Drawer
      placement="left"
      closable={false}
      onClose={() => props.onShowDrawer(false)}
      visible={props.showDrawer}
      key="left"
      bodyStyle={{
        padding: "0px",
        backgroundColor: "#fff",
      }}
      contentWrapperStyle={{ width: "200px" }}
    >
      {NavMenu}
    </Drawer>
  );
}
