import { Routes, Route } from "react-router-dom";
import List from "./List";
import View from "./View";
export const MODEL = "device";
export const BASE_PATH = "/registered-devices";
export const TITLE = ["Registered Device", "Registered Devices"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/`} element={<List />} />
      <Route path={`/:id`} element={<View />} />
    </Routes>
  );
}
