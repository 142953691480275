import { Store } from "@store/index";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function useClient() {
  const authState = useSelector((state: Store) => state.auth);
  const [client, setClient] = useState<string>("");
  useEffect(() => {
    if (authState.user?.client) {
      setClient(authState.user?.client);
    } else {
      setClient("");
    }
  }, [authState.user]);

  return client;
}

export default useClient;
