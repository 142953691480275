import { createSlice } from "@reduxjs/toolkit";

export interface UrlState {
  breadcrumbs: { label: string; url: string }[];
}
const INITIAL_STATE: UrlState = {
  breadcrumbs: [],
};
const slice = createSlice({
  name: "url",
  initialState: INITIAL_STATE,
  reducers: {
    setBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
export const { setBreadcrumbs } = slice.actions;
export default slice.reducer;
