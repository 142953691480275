import { Routes, Route } from "react-router-dom";
import List from "./List";
import Form from "./Form";
import View from "./View";
import { Tag } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
export const MODEL = "asset";
export const BASE_PATH = "/assets";
export const TITLE = ["Assets", "Assets"];

export const ProcessingStatusBadge = ({ record, status, isLoading }) => {
  if (record.mimeType?.includes("video") && status) {
    status =
      status === "COMPLETE"
        ? "Completed"
        : status === "SUBMITTED"
        ? "Pending"
        : "Processing";
  }
  return record.mimeType?.includes("video") && isLoading ? (
    <div className="flex items-center gap-x-2">
      <LoadingOutlined /> Loading...
    </div>
  ) : status === "Completed" ? (
    <Tag color="green">{status}</Tag>
  ) : status === "Pending" ? (
    <Tag color="gold">{status}</Tag>
  ) : (
    <Tag color="blue">{status}</Tag>
  );
};

export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/new`} element={<Form />} />
      <Route path={`/edit`} element={<Form />} />
      <Route path={`/`} element={<List />} />
      <Route path={`/:id`} element={<View />} />
    </Routes>
  );
}
