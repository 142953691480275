export default function getThumbnailUrl(mimeType: string, processedUrls: any) {
  if (!processedUrls || processedUrls.length === 0) {
    return "";
  }
  const resolutions = Object.keys(processedUrls);
  const url = processedUrls[resolutions[resolutions.length - 1]];
  if (!url) {
    return "";
  }
  const folder = url.substring(0, url.lastIndexOf("/") + 1);
  const imageUrl = folder + url.split("/").pop().split(".")[0] + ".0000000.jpg";
  const isVideo = mimeType.includes("video");
  return isVideo ? imageUrl : url;
}
