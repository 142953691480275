import { createSlice } from "@reduxjs/toolkit";

export interface UIState {
  showSider: boolean;
}
const INITIAL_STATE: UIState = {
  showSider: true,
};
const slice = createSlice({
  name: "ui",
  initialState: INITIAL_STATE,
  reducers: {
    setShowSider: (state, action) => {
      state.showSider = action.payload;
    },
  },
  extraReducers: (builder) => {},
});
export const { setShowSider } = slice.actions;
export default slice.reducer;
