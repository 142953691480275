import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import authReducer, { AuthState } from "./authSlice";
import urlReducer, { UrlState } from "./urlSlice";
import uiReducer, { UIState } from "./uiSlice";
import projectReducer, { ProjectState } from "./projectSlice";

export interface MasterEntityState {
  pagination: {
    list: any[];
    queryCount: number;
    totalCount: number;
  };
  entities: any[];
  current: any;
  viewLoading: boolean;
  viewError: any;
  loadLoading: boolean;
  loadError: any;
  formLoading: boolean;
  formError: any;
  progress: { percent: any; text: any };
}

export interface Store {
  auth: AuthState;
  project: ProjectState;
  url: UrlState;
  ui: UIState;
}

const rootReducer = {
  auth: authReducer,
  project: projectReducer,
  url: urlReducer,
  ui: uiReducer,
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistCombinedReducers = persistCombineReducers(
  persistConfig,
  rootReducer
);

export const store = configureStore({
  reducer: persistCombinedReducers,
  middleware: [thunk],
  devTools: true,
});

export const persistor = persistStore(store);
