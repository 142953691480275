import io from "socket.io-client";
import { SOCKET_URL } from "./apiUrl";
import { debounce } from "throttle-debounce";
import { store } from "../../store";
export let socket: any;

export const initialize = debounce(500, async () => {
  if (!socket) {
    const { store } = await import("../../store");
    console.log("Initializing socket on", SOCKET_URL);
    socket = io(SOCKET_URL);
  }
});

export const terminate = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
    socket = undefined;
  }
};
