// import { store } from "store";
import storage from "redux-persist/lib/storage";
import { logout as postLogout } from "../services/auth";
const logout = async (isExpired?: boolean) => {
  try {
    await postLogout();
  } catch (error) {}
  // clears localStorage data
  storage.removeItem("persist:root");
  storage.removeItem("externalId");
  localStorage.removeItem("branch");
  if (window.location.pathname !== "/auth") {
    window.location.href = `/auth?${isExpired ? "session-expired=true" : ""}`;
  }
};

export default logout;
