import axios, { CancelTokenSource } from "axios";
export default class UploadAdapter {
  uploadUrl: string;
  cancelToken: CancelTokenSource;
  progress: any;
  dimensions: any;
  handleProgress: any;
  constructor(handleProgress: any, uploadUrl: string, dimensions: any) {
    this.handleProgress = handleProgress;
    this.uploadUrl = uploadUrl;
    this.dimensions = dimensions;
  }
  upload(file: File) {
    return new Promise(async (resolve, reject) => {
      const cancelTokenSource = axios.CancelToken.source();
      this.cancelToken = cancelTokenSource;
      const response = await this._getPreSignedURL(
        file.name,
        file.type,
        this.dimensions
      );
      const { uploadURL, photoFilename } = <
        { uploadURL: string; photoFilename: string }
      >response.data;
      const uploadFile = new File([file], photoFilename, {
        type: file.type,
      });
      axios
        .put(uploadURL, uploadFile, {
          cancelToken: this.cancelToken.token,
          onUploadProgress: (progressEvent) => {
            this.handleProgress(progressEvent);
          },
        })
        .then((response) => {
          return resolve({
            default: uploadURL.substring(0, uploadURL.indexOf("?") + 1),
          });
        })
        .catch(reject);
    });
  }
  _getPreSignedURL(filename: string, contentType: string, dimensions: any) {
    return axios.get(
      `${this.uploadUrl}?filename=${encodeURIComponent(
        filename
      )}&contentType=${encodeURIComponent(contentType)}&width=${
        dimensions.width
      }&height=${dimensions.height}`
    );
  }
}
