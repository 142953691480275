import { Layout, Menu, Avatar, Dropdown, Button, Space, Select } from "antd";
import { UserOutlined, MenuOutlined } from "@ant-design/icons";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { Store } from "@store";
import Logo from "@components/Vectors/Logo";
import LogoPNG from "../../assets/logo.png";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PiFactoryBold } from "react-icons/pi";
const { Header: AntHeader } = Layout;
export default function Header(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const AccountMenu = (
    <Menu style={{ top: -15 }}>
      <Menu.Item
        key="0"
        icon={<FaUserCircle />}
        className="flex items-center"
        onClick={props.onProfile}
      >
        <span className="ml-2">Account</span>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key="1"
        icon={<FaSignOutAlt />}
        className="flex items-center"
        onClick={props.onLogout}
      >
        <span className="ml-2">Logout</span>
      </Menu.Item>
    </Menu>
  );
  const handleSelectProject = () => {};
  return (
    <AntHeader>
      <div className="h-full flex  justify-between items-center">
        <div className="flex items-center ">
          <div
            className="flex items-center h-full cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img src={LogoPNG} width="160" className="p-4" />
          </div>
          <div>
            <Space>
              {!props.showNav ? (
                <Button
                  type="primary"
                  onClick={() => {
                    props.onShowDrawer(true);
                  }}
                  icon={<MenuOutlined />}
                  size="large"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                />
              ) : undefined}
            </Space>
          </div>
        </div>
        <div className="grid place-items-center grid-flow-col gap-x-8">
          <Dropdown overlay={AccountMenu} trigger={["click"]} className="">
            <div className="flex items-center cursor-pointer">
              <Avatar
                icon={<UserOutlined />}
                size={24}
                src={props.authState?.user?.image}
              />
              <span className="ml-2 capitalize text-white">
                {props.user?.name || "John Smith"}
              </span>
            </div>
          </Dropdown>
        </div>
      </div>
    </AntHeader>
  );
}
