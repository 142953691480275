import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/device";
const CLIENT_SERVICE_URL = "/client-device";

export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const toggleActive = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/toggle-active`);
export const checkMacAddress = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-mac-address?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const processBulk = (payload: any) =>
  postRequest(`${SERVICE_URL}/bulk-process`, payload);
export const uploadBulk = (formData: FormData) =>
  postRequest(`${SERVICE_URL}/bulk`, formData);
export const exportToXLSX = () => postBlobRequest(`${SERVICE_URL}/export`);
export const getAllForClient = (client: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/all?client=${client}`);
export const getUnAssociated = (group: string) =>
  getRequest(`${CLIENT_SERVICE_URL}/unassociated?group=${group}`);
export const paginateForClient = (qs: string) =>
  getRequest(`${CLIENT_SERVICE_URL}?${qs}`);
export default {
  create,
  update,
  paginate,
  get,
  getAll,
  toggleActive,
  checkMacAddress,
  processBulk,
  uploadBulk,
  exportToXLSX,
  paginateForClient,
  getAllForClient,
  getUnAssociated,
};
