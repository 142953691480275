import { getRequest, postRequest, putRequest } from "./config/request";
const SERVICE_URL = "/campaign";
export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const createDefault = (payload) =>
  postRequest(`${SERVICE_URL}/default`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getDefault = () => getRequest(`${SERVICE_URL}/default`);
export const checkCode = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-code?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const getCampaignsForGroups = (groups: string[]) =>
  getRequest(`${SERVICE_URL}/groups?groups=${groups.join(",")}`);
export default {
  create,
  update,
  paginate,
  get,
  getAll,
  checkCode,
  getCampaignsForGroups,
  createDefault,
  getDefault,
};
