import { Form, Input, Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Store } from "../store";
import * as authActions from "../store/authSlice";

export default function Login(props: any) {
  const navigate = useNavigate();
  const authState = useSelector((state: Store) => state.auth);
  const dispatch = useDispatch();
  const [loginForm] = Form.useForm();
  const handleContinue = async (values: any) => {
    try {
      (
        dispatch(
          authActions.loginAsync({
            username: values.username,
            password: values.password,
          })
        ) as any
      ).then((response) => {
        if (!response.error) {
          navigate("/");
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col bg-primary-500 h-screen min-h-screen">
      <div className="absolute w-full">
        <div className="py-4 flex justify-center"></div>
      </div>
      <div className="flex flex-col items-center justify-center container mx-auto max-w-max grow h-full">
        <div className="flex flex-col bg-white rounded p-8 justify-around ">
          <div className="container mx-auto flex flex-col pt-1">
            <Form form={loginForm} onFinish={handleContinue} className="">
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password placeholder="Password" />
              </Form.Item>
              <Button
                block
                loading={authState.loading}
                htmlType="submit"
                type="primary"
                className="mt-2"
              >
                Continue
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
