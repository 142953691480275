import { Store } from "@store/index";
import { Button, Typography } from "antd";
import { useSelector } from "react-redux";
import Vector from "@components/Vectors/Home";
const { Title } = Typography;
export default function Home(props: any) {
  const projectState = useSelector((state: Store) => state.project);
  return (
    <div>
      <div className="flex items-center justify-between">
        <div>
          <div>
            <Title>Welcome</Title>
          </div>
          <div>
            <div>
              <span className="text-[22px]" style={{ fontSize: "18px" }}>
                You're working in{" "}
                <span className="font-semibold">
                  {projectState.currentSelected?.name}
                </span>
              </span>
            </div>
            <div className="mt-2">
              <span>
                Project Code:{" "}
                <span className="font-semibold">
                  {projectState.currentSelected?.code}
                </span>
              </span>
            </div>
            <div className="mt-4 flex gap-x-4">
              <span className="text-blue-500 font-semibold cursor-pointer">
                Dashboard
              </span>
              <span className="text-blue-500 font-semibold cursor-pointer">
                Recommendations
              </span>
            </div>
          </div>
          <div>{/* Project Highlights */}</div>
        </div>
        <div>
          <Vector />
        </div>
      </div>
    </div>
  );
}
