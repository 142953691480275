import {
  getRequest,
  postBlobRequest,
  postRequest,
  putRequest,
} from "./config/request";
const SERVICE_URL = "/device-group";

export const create = (payload) => postRequest(`${SERVICE_URL}`, payload);
export const update = (payload) => putRequest(`${SERVICE_URL}`, payload);
export const paginate = (qs: string) => getRequest(`${SERVICE_URL}?${qs}`);

export const get = (id) => getRequest(`${SERVICE_URL}/${id}`);
export const getAll = () => getRequest(`${SERVICE_URL}/all`);
export const toggleActive = (id: string) =>
  getRequest(`${SERVICE_URL}/${id}/toggle-active`);
export const checkCode = (name: string, id: string) =>
  getRequest(
    `${SERVICE_URL}/check-code?name=${encodeURIComponent(
      name
    )}&id=${encodeURIComponent(id)}`
  );
export const processBulk = (payload: any) =>
  postRequest(`${SERVICE_URL}/bulk-process`, payload);
export const uploadBulk = (formData: FormData) =>
  postRequest(`${SERVICE_URL}/bulk`, formData);
export const exportToXLSX = () => postBlobRequest(`${SERVICE_URL}/export`);

export default {
  create,
  update,
  paginate,
  get,
  getAll,
  toggleActive,
  checkCode,
  processBulk,
  uploadBulk,
  exportToXLSX,
};
