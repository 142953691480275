import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import * as authActions from "../store/authSlice";
import { Breadcrumb, Menu, Spin, theme } from "antd";
import type { MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "antd";
import Header from "@components/Header";
import { useEffect, useState } from "react";
import useBreakpoint from "../utils/useBreakpoint";
import Logout from "../utils/logout";

import { Store } from "@store";
import Navigation from "../components/Navigation";

import Clients from "@views/Clients";
import ClientDevices from "@views/ClientDevices";
import Devices from "@views/Devices";
import Assets from "@views/Assets";
import DeviceGroups from "@views/DeviceGroups";
import Settings from "@views/Settings";
import DefaultCampaign from "@views/Settings/DefaultCampaign";
import Campaigns from "@views/Campaigns";
import Home from "@views/Home";
import { initialize, terminate } from "@services/config/progress";
import useIsAdmin from "@hooks/useIsAdmin";
function BreadcrumbComponent(props: any) {
  const location = useLocation();
  const urlState = useSelector((state: Store) => state.url);
  const navigate = useNavigate();
  useEffect(() => {
    console.log("Route changed to:", location.pathname);
  }, [location]);

  return (
    <Breadcrumb>
      {urlState.breadcrumbs.map((b) => (
        <Breadcrumb.Item>
          <a onClick={() => navigate(b.url)}>{b.label}</a>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
}

function AppLayout(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [showNav, setShowNav] = useState<boolean>(true);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const breakpoint = useBreakpoint();
  const uiState = useSelector((state: Store) => state.ui);
  const isAdmin = useIsAdmin();
  const authState = useSelector((state: Store) => state.auth);
  useEffect(() => {
    dispatch(authActions.getUserAsync());
  }, []);
  useEffect(() => {
    if (authState.user) {
      initialize();
    } else {
      terminate();
    }
  }, [authState.user]);
  return (
    <Layout className="min-h-screen">
      <Header
        showNav={showNav}
        onShowDrawer={setShowDrawer}
        onLogout={() => Logout()}
        onProfile={() => navigate("/profile")}
        user={authState.user}
      />
      <Layout>
        <Navigation
          showNav={showNav}
          collapsed={collapsed}
          onCollapse={setCollapsed}
          onShowDrawer={setShowDrawer}
          onNavigate={navigate}
          showDrawer={showDrawer}
          authState={authState}
        />
        <Layout>
          {uiState.showSider && (
            <div className="px-6 mt-4">
              <BreadcrumbComponent />
            </div>
          )}
          <div className="p-6">
            <Routes>
              <Route path="/" element={<Home />} />
              {isAdmin ? (
                <>
                  <Route path="/clients/*" element={<Clients />} />
                  <Route path="/devices/*" element={<Devices />} />
                </>
              ) : (
                <>
                  <Route
                    path="/registered-devices/*"
                    element={<ClientDevices />}
                  />
                  <Route path="/campaigns/*" element={<Campaigns />} />
                  <Route path="/device-groups/*" element={<DeviceGroups />} />
                  <Route path="/assets/*" element={<Assets />} />
                  <Route path="/settings/*" element={<Settings />} />
                </>
              )}
            </Routes>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default AppLayout;
