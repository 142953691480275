import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Typography,
  Input,
  Select,
  Button,
  Space,
  Spin,
  Divider,
} from "antd";
import { TITLE, BASE_PATH } from ".";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import querystring from "query-string";
import service from "@services/device";
import useBreadcrumbs from "@hooks/useBreadcrumbs";

const { Title } = Typography;
const { Option } = Select;

export default function EntityForm(props: any) {
  const breadcrumbsRef = useRef([
    { label: TITLE[1], url: BASE_PATH },
    { label: "Device Form" },
  ]);
  const [form] = Form.useForm();
  const [id, setId] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadLoading, setLoadLoading] = useState(false);
  const [dependentsLoading, setDependentsLoading] = useState(false);
  const [masters, setMasters] = useState<any>({});
  const setBreadcrumbs = useBreadcrumbs();
  const [errors, setErrors] = useState<any>({
    basic: false,
    material: false,
    fluid: false,
    test: false,
  });
  const handleSubmit = (values: any) => {
    console.log(values);
    setLoading(true);
    if (id) {
      service
        .update({ ...values, _id: id })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      service
        .create({ ...values })
        .then((response) => {
          if (!response.error) {
            navigate(BASE_PATH);
          }
        })
        .catch((err) => {})
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleCancel = () => {
    navigate(BASE_PATH);
  };
  useEffect(() => {
    const params: any = querystring.parse(location.search);
    if (!_.isEmpty(params)) {
      setId(params.entity);
      setLoadLoading(true);
      service
        .get(params.entity)
        .then((payload) => {
          form.setFieldsValue({ ...payload });
        })
        .finally(() => setLoadLoading(false));
    }
  }, [location]);
  useEffect(() => {
    handleLoadDependencies();
    setBreadcrumbs(breadcrumbsRef.current);
  }, []);
  const handleLoadDependencies = async () => {
    try {
      setDependentsLoading(true);
      const promises = [];
      const [] = await Promise.all(promises);
      setMasters({});
    } catch (error) {
    } finally {
      setDependentsLoading(false);
    }
  };

  return (
    <div>
      <Title level={3}>
        {id ? "Update" : "New"} {TITLE[0]}
      </Title>
      <Spin spinning={loadLoading}>
        <div className="mt-4 bg-white shadow px-4 py-4">
          <Form layout="vertical" form={form} onFinish={handleSubmit} preserve>
            <div className="flex flex-wrap ">
              <Form.Item
                className="w-1/2 px-2"
                label="MAC Address"
                rules={[
                  { required: true, message: "MAC Address is required" },
                  {
                    validator: async (_, value) => {
                      const response = await service.checkMacAddress(
                        value?.trim().toUpperCase(),
                        id
                      );
                      return response.exists
                        ? Promise.reject("MAC Address already exists")
                        : Promise.resolve();
                    },
                  },
                ]}
                name="macAddress"
              >
                <Input placeholder="Enter MAC Address" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Remote"
                rules={[{ required: true, message: "Remote is required" }]}
                name={["remote"]}
              >
                <Input placeholder="Enter Remote" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="OS Version"
                rules={[{ required: true, message: "OS Version is required" }]}
                name="osVersion"
              >
                <Input placeholder="Enter OS Version" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Platform Name"
                rules={[
                  { required: true, message: "Platform Name is required" },
                ]}
                name={["platformName"]}
              >
                <Input placeholder="Enter Platform Name" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Vendor Name"
                rules={[{ required: true, message: "Vendor Name is required" }]}
                name={["vendorName"]}
              >
                <Input placeholder="Enter Vendor Name" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Vendor ID"
                rules={[{ required: true, message: "Vendor ID is required" }]}
                name="vendorId"
              >
                <Input placeholder="Enter Vendor ID" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Vendor Product ID"
                rules={[
                  { required: true, message: "Vendor Product ID is required" },
                ]}
                name="vendorProductId"
              >
                <Input placeholder="Enter Vendor Product ID" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Product ID"
                rules={[{ required: true, message: "Product ID is required" }]}
                name={["productId"]}
              >
                <Input placeholder="Enter Product ID" />
              </Form.Item>

              <Form.Item
                className="w-1/2 px-2"
                label="Launcher Version"
                rules={[
                  { required: true, message: "Launcher Version is required" },
                ]}
                name={["launcherVersion"]}
              >
                <Input placeholder="Enter Launcher Version" />
              </Form.Item>
              <Form.Item
                className="w-1/2 px-2"
                label="Content Type"
                rules={[
                  { required: true, message: "Content Type is required" },
                ]}
                name={["contentType"]}
              >
                <Input placeholder="Enter Content Type" />
              </Form.Item>

              <Form.Item
                className="w-1/2 px-2"
                label="Stage"
                rules={[{ required: true, message: "Stage is required" }]}
                name={["stage"]}
              >
                <Input placeholder="Enter Stage" />
              </Form.Item>
              <Form.Item className="w-1/2 px-2" label="Flag" name={["flag"]}>
                <Input placeholder="Enter Flag" />
              </Form.Item>

              <Form.Item
                className="w-1/2 px-2"
                label="Record Version"
                name={["recordVersion"]}
              >
                <Input placeholder="Enter Record Version" />
              </Form.Item>
            </div>

            <div className="mt-3">
              <Form.Item noStyle>
                <Space>
                  <Button type="default" onClick={handleCancel}>
                    Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Submit
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
}
