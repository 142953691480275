import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getUser, logout, login } from "../services/auth";
export const getUserAsync = createAsyncThunk(
  "auth/GET_USER",
  async (payload, thunkAPI) => {
    try {
      const response = await getUser();
      return response.data?.result ? response.data.result : response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const loginAsync = createAsyncThunk(
  "auth/LOGIN",
  async (payload: any, thunkAPI) => {
    try {
      const response = await login(payload);
      return response.data?.result ? response.data.result : response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export const logoutAsync = createAsyncThunk(
  "auth/LOGOUT",
  async (payload, thunkAPI) => {
    try {
      const response = logout();
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);
export interface AuthState {
  user: any;
  role: any;
  token: string;
  loading: boolean;
  error: any;
}
const INITIAL_STATE: AuthState = {
  user: null,
  role: null,
  token: null,
  error: null,
  loading: false,
};
const slice = createSlice({
  name: "auth",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginAsync.pending, (state, action) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(loginAsync.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    });
    builder.addCase(loginAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.token = action.payload?.token;
    });
    builder.addCase(getUserAsync.pending, (state, action) => {
      state.loading = true;
      state.user = null;
      state.error = null;
    });
    builder.addCase(getUserAsync.rejected, (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    });
    builder.addCase(getUserAsync.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
      state.error = null;
      if (!state.user.isAdmin) {
        localStorage.setItem("client", state.user.client);
      }
    });
    builder.addCase(logoutAsync.pending, (state, action) => {});
    builder.addCase(logoutAsync.fulfilled, (state, action) => {
      state.user = null;
    });
  },
});

export default slice.reducer;
