import { Routes, Route } from "react-router-dom";
import DefaultCampaign from "./DefaultCampaign";
export const MODEL = "client";
export const BASE_PATH = "/clients";
export const TITLE = ["Client", "Clients"];
export default function Entity(props: any) {
  return (
    <Routes>
      <Route path={`/default-campaign`} element={<DefaultCampaign />} />
    </Routes>
  );
}
